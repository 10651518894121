<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="Product" prop="brandProduct">
<!--                <a-input v-model="queryParam.brandProduct" placeholder="" allow-clear/>-->
                <a-select
                  placeholder="-Product-"
                  style="width: 100%"
                  showSearch
                  v-model="queryParam.brandProduct"
                  optionFilterProp="title"
                >
                  <a-select-option v-for="item in brandSkuList" :key="item.id" :value="item.id" :title="item.name + '('+item.productModel+')'">
                    {{ item.name+ '('+item.productModel+')' }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="Identification code" prop="code">-->
<!--                <a-input v-model="queryParam.code" placeholder="" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />Search</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />Clear All</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()">
          <a-icon type="plus" />Add
        </a-button>
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['cigarette:code:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['cigarette:code:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['cigarette:code:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="skuId"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >

        <div slot="brandProduct" slot-scope="text, record">
          <div>{{record.name}}({{record.productModel}})</div>
        </div>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:code:edit']" />-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)">
            <a-icon type="edit" />Modify
          </a>
<!--          <a-divider type="vertical"/>-->
<!--          <a @click="handleDelete(record)">-->
<!--            <a-icon type="delete" />Delete-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页
        show-size-changer-->
      <a-pagination
        class="ant-table-pagination"
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `${total} results`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageCode,listCode, delCode } from '@/api/cigarette/code'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {listSku} from "@/api/cigarette/goodSku";

export default {
  name: 'Code',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      brandSkuList: [],
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        brandProduct: null,
        code: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: 'Identification Code',
        //   dataIndex: 'code',
        //   align: 'center'
        // },
        {
          title: 'Product',
          dataIndex: 'brandProduct',
          align: 'center',
          scopedSlots: { customRender: 'brandProduct' },
        },
  //       {
  //         title: 'Status',
  //         dataIndex: 'status',
  //         align: 'center',
  //         customRender: (t,r) =>{
  //         if(r.status == 1
  // )
  //   {
  //     return 'Enable'
  //   }
  // else
  //   {
  //     return 'Disable'
  //   }
  // }
  //       },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getBrandSkuList(null);

    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getBrandSkuList(brandId) {
      listSku({
        status:1
      }).then(res => {
        this.brandSkuList = res.data;
      })
    },
    /** 查询新-产品识别码列表 */
    getList () {
      this.loading = true
     pageCode(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        brandProduct: undefined,
        code: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delCode(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/identification-code/export', {
            ...that.queryParam
          }, `新-产品识别码_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
